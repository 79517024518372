<template>
  <div>
    <nav>
      <ul>
        <li>
          <router-link to="/" exact class="nav-link" active-class="active"
            >Accueil</router-link
          >
        </li>
        <li>
          <router-link
            to="/accompagnement"
            class="nav-link"
            exact
            active-class="active"
            >Accompagnements thérapeutiques</router-link
          >
        </li>
        <li>
          <router-link
            to="/ateliers"
            class="nav-link"
            exact
            active-class="active"
            >Cercles de parole</router-link
          >
        </li>
        <li>
          <router-link to="/doula" class="nav-link" exact active-class="active"
            >Doula de fin de vie</router-link
          >
        </li>
        <li v-if="isConnected">
          <button @click="disconnect">Déconnexion</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import router from "../../router";

  export default {
    name: "Nav",
    data() {
      return {
        isMobile: false,
        isOpened: false,
        isConnected: localStorage.getItem("isConnected") || false,
      };
    },
    methods: {
      disconnect: function () {
        localStorage.clear();
        this.isConnected = false;
        router.push(this.$router.go());
      },
      handleClick: function () {
        this.isOpened = !this.isOpened;
      },
    },
    computed: {
      toggle: function () {
        return this.isOpened ? "open" : "";
      },
    },
    mounted() {
      if (document.documentElement.clientWidth < 1024) {
        this.isMobile = true;
      }
    },
  };
</script>

<style scoped>
  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding: 0;
  }

  li a:hover:not(.active),
  li a:focus:not(.active) {
    background: #403248;
    color: #fff;
  }
  .nav-link {
    text-decoration: none;
    color: black;
  }

  .active {
    background-color: #9C7EAD;
    padding: 1rem;
    border-radius: 0.5rem;
    color: white;
  }

  @media screen and (min-width: 1024px) {
    li a {
      padding: 1rem;
      border-radius: 0.5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    ul,
    li,
    li a {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;
    }
    li a {
      padding: 5px;
      color: #888;
      text-decoration: none;
      transition: all 0.2s;
    }
  }
</style>

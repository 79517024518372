import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret, faAd, faEnvelope, faPhone, faSeedling, faHandHoldingHeart, faHeart, faStarOfLife, faHouseUser, faPen, faTrash, faUser} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram} from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VCalendar from 'v-calendar';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import vClickOutside from "click-outside-vue3";
import AOS from 'aos';
import 'aos/dist/aos.css';

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon);

library.add(faUserSecret);
library.add(faAd);
library.add(faFacebookSquare);
library.add(faInstagram);
library.add(faEnvelope);
library.add(faPhone);
library.add(faSeedling);
library.add(faHandHoldingHeart);
library.add(faHeart);
library.add(faStarOfLife);
library.add(faHouseUser);
library.add(faPen);
library.add(faTrash);
library.add(faUser);

app.use(VCalendar, {});
app.use(VueToast);
app.use(vClickOutside);
app.use(AOS.init())


app.config.productionTip = false

app.use(router)

app.mount('#app')

<template>
  <div class="form">
    <form ref="form" @submit.prevent="sendEmail" class="form__container">
      <label>Prénom :*</label>
      <input type="text" name="from_name" required />
      <label>Adresse Mail :*</label>
      <input type="email" name="from_email" required />
      <label>Téléphone :</label>
      <input type="tel" name="phone" />
      <label>Message :*</label>
      <textarea name="message" required></textarea>
      <input class="send-btn" type="submit" value="Envoyer" />
    </form>
  </div>
</template>

<script>
  import emailjs from "emailjs-com";

  export default {
    computed: {
    
    reversedMessage: function () {
      
      return '6Lc6Q9gpAAAAAFb3JbjuSl-wAhYl-Ec_y21kJ58s'
    }
  },
    methods: {
      sendEmail() {
        emailjs
          .sendForm(
            "service_3pt4n6m",
            "template_i5c4kuk",
            this.$refs.form,
            "Wn-dvPbGCnDRp6Q67"
          )
          .then(
            () => {
              this.openSuccessToast();
              this.$refs.form.reset();
            },
            (error) => {
              this.openFailToast(error);
              console.log("FAILED...", error.text);
            }
          );
      },
      openSuccessToast() {
        this.$toast.open({
          message: "Votre message a bien été envoyé",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: 'top',
        });
      },
      openFailToast() {
        this.$toast.open({
          message: "Oups, une erreur est survenue",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top',
        });
      },
    },
  };
</script>

<style scoped>
  .form {
    margin: auto;
  }

  .form__container {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .form__container label {
    text-align: left;
    margin-top: 2rem;
  }

  .form__container textarea {
    min-height: 110px;
    min-width: 231px;
    max-width: 231px;
  }

  .form__container input {
    height: 1.5rem;
  }

  .send-btn {
    padding: 1rem;
    height: auto !important;
    border-radius: 1rem;
    margin: 2rem auto;
    cursor: pointer;
    background-color: white;
    font-weight: 900;
  }

  .send-btn:hover {
    background-color: #2fad2f91;
  }
</style>

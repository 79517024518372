<template>
    <div>
        <Nav />
        <Header />
        <h1>STAGE</h1>
    </div>
</template>

<script>
    import Nav from '../navigation/Nav.vue';
    import Header from '../navigation/Header.vue';
    
    export default {
      name: 'Stage',
      components: {
        Nav,
        Header
      },
    }
    </script>
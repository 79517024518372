<template>
    <div>
        <Nav />
        <Header />
        <h1>Accompagnements thérapeutiques</h1>
        <section v-for="(accompagnement, index) in accompagnements" :key="index">
            <div v-for="(type, indexType) in accompagnement" :key="indexType" :id="type.id" class="wrapper">
                <div class="wrapper__content">
                    <h2>{{type.name}}</h2>
                    <blockquote>
                        <p>{{type.quote}}</p>
                    </blockquote>
                    <p v-for="(accroche, indexAccroche) in type.accroche" :key="indexAccroche">{{accroche}}</p>
                    <p v-for="(description, indexDescription) in type.description" :key="indexDescription">{{description}}</p>
                    <ul>
                        <li v-for="(benefit, indexBenefit) in type.benefits" :key="indexBenefit" class="benefits">{{benefit}}</li>
                    </ul>
                    <h4>{{type.priceTitle}}</h4>
                    <ul>
                        <li v-for="(price, indexPrice) in type.prices" :key="indexPrice">{{price}}</li>
                    </ul>
                </div>
                <div class="wrapper__image">
                    <img :src="getImgUrl(type.img.url)" :alt="type.img.name">
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
    import Nav from '../navigation/Nav.vue';
    import Header from '../navigation/Header.vue';
    import Footer from '../navigation/Footer.vue';

    export default {
        name: 'Accompagnements',
        components: {
            Footer,
            Header,
            Nav
        },
        methods: {
            getImgUrl(pet) {
                var images = require.context('../../assets/', false, /\.jpeg$/)
                return images('./' + pet + ".jpeg")
              }
        },
        data() {
            return {
                accompagnements: {
                    massages: {
                        ayurvédique: {
                            id: "ayurvedique",
                            name: "Massage Ayurvédique",
                            quote: "« Fais du bien à ton corps pour que ton âme ait envie d’y rester » Proverbe indien. ",
                            accroche: [
                                "Vous avez envie de reconnecter votre corps et votre esprit pour vous reconnecter à vous m’aime ?",
                                "Vous souhaitez un massage adapté à votre besoin du moment ?",
                                "Vous avez besoins de libérer votre mental, de douceur et de voyager ?"
                            ],
                            description: [
                                "Le massage ayurvédique est une technique qui nous vient de l’Inde. En effet, le massage est un rituel nécessaire et qui a toute son importance dans la tradition indienne.",
                                "Dès la naissance, jusqu’à la fin de vie, le massage accompagne les transitions de vie comme rituels de passages.",
                                "Le massage a pour vertu de maintenir le corps en bonne santé, notamment par l’équilibre des énergies qui le composent.",
                                "Le Massage Abhyanga à l’huile ayurvédique bio chaude offre par sa technique : "
                            ],
                            benefits: [
                                "Une augmentation de la circulation nerveuse notamment", 
                                "Apaise le système nerveux", 
                                "Tonifie les muscles et l’esprit", 
                                "Améliore le sommeil", 
                                "Adouci la peau", 
                                "Détoxifiant"
                            ],
                            priceTitle: "Tarifs du massage ayurvédique traditionnel aux huiles bios chaudes :",
                            prices: [
                                "30 mn : 45 euros", 
                                "60 mn : 80 euros", 
                                "90 mn : 100 euros"
                            ],
                            img: {
                                url: "massage",
                                name: "massage ayurvédique"
                            }
                        },
                        energetique: {
                            id: "energetique",
                            name: "Massage énergétique intuitif",
                            quote: "Ce massage est avant tout un voyage, une rencontre avec soi, dans nos profondeurs.",
                            description: [
                                "Massage aux huiles chaudes avec possibilités d’utilisation d’hydrolats ou d’huiles essentielles.",
                                "Ce massage portera votre signature énergétique du moment, vos besoins et des besoins que votre corps m’exprimera dans l’instant.",
                                "Il sera également proposé l’utilisation des bols tibétains, des carillons, un véritable voyage sonore.",
                                "Ce massage offre : "],
                            benefits: [
                                "Un apaisement globalement du corps, du mental et de l’esprit", 
                                "Un réalignement et harmonisation des énergies qui nous constituent",
                                "Une rencontre avec soi et de ce qui est présent dans l’instant",
                                "Des libérations émotionnelles, compréhensions"
                            ],
                            priceTitle: "Tarif du massage énergétique : ",
                            prices: ["60 mn : 80 euros"],
                            img: {
                                url: "massage-energetique",
                                name: "massage energetique"
                            }
                        },
                        metamorphique: {
                            id: "metamorphique",
                            name: "Massage Métamorphique",
                            quote:"« Ce que la chenille appelle la fin du monde, le Maître l’appelle un papillon » Richard Bach",
                            accroche: [
                                "De la crysalide au papillon, offrez-vous une métamorphose corporelle et psychique grâce à ce massage relaxant des mains, pieds et de la tête.",
                                "Vous souhaitez changer et dynamiser votre vie, faire la paix avec votre naissance ?",
                                "Votre enfant vous montrent une hyperactivité, hypersensibilité, des troubles du sommeil, du langage, de l’alimentation etc.",
                                "Ce massage est fait pour vous."
                            ],
                            description: [
                                "Le terme « métamorphose » désigne en grecque un changement, une transformation ou une modificaiton.",
                                "Le massage métamorphique est un massage très doux offrant la possibilité de se reconnecter à ses potentiels et ses ressources intérieures.",
                                "La spécificité de cette technique est d’activer le potentiel d’auto-guérison du corps et de l’esprit.",
                                "Par un toucher relaxant par des mouvements et rythmes différents sur les zones réflexes de la colonne vertébrale située sur les mains, pieds er de la tête.",
                                "Issues de la réflexologie, ces zones sont en lien avec direct avec la globalité de notre corps et sont en lien avec les mémoires de notre vie fœtale.",
                                "Libérations :"
                            ],
                            benefits: [
                                "De la force de vie transformant les souffrances du passé",
                                "Des souffrances de la vie prénatale",
                                "Anciennes mémoires façonnant notre personnalité créant parfois des maladies",
                                "Un nouveau potentiel d’énergie",
                                "Rôle actif de votre part",
                                "Vous ouvrir à votre ressenti afin qu’opére la métamorphose",
                                "Laisser couler librement la force vitale en vous",
                                "Accepter d’être touché aux pieds, aux mains et à la tête"
                            ],
                            priceTitle: "Tarif du massage métamorphique :",
                            prices: [
                                "Enfants 30 minutes : 30 euros", 
                                "1h : 60 euros"
                            ],
                            img: {
                                url: "massage-meta",
                                name: "massage metamorphique"
                            }
                        },
                    },
                    psychogene: {
                        information: {
                            id: "psychoge",
                            name: "Psychogénéalogie",
                            description: [
                                "Aller visiter son histoire au travers du prisme de l’histoire familiale. Se rencontrer au travers de ses racines et de ses loyautés transgénérationnelles bien souvent inconscientes.",
                                "S’approprier son héritage, comprendre les transmissions inconscientes afin d’intégrer sa place dans l’arbre avec plus de sens.",
                                "Reconstruire son histoire familiale avec les informations que vous aurez en votre possession.", 
                                "C’est un accompagnement qui demande du temps, de la patience et un engagement profond envers soi.",
                                "Cet accompagnement engage le corps et l’émotionnel, et vous permettre de retrouver votre verticalité et votre ancrage nourrit d’une sève ascendante."
                            ],
                            img: {
                                url: "psychoge",
                                name: "psycho généalogie"
                            }
                        }
                    },
                    psychocorpo: {
                        information: {
                            id: "psychocorpo",
                            name: "Accompagnement psycho corporel",
                            description: [
                                "Accompagnement psychocorporelle de l’adulte dans la traversée des tempêtes de la vie : Difficultés, événements, transitions.",
                                "La vie est un grand terrain de jeu parsemé d’expériences. Certaines d’entre elles sont plus lourdes à traverser, à y trouver un sens. S’offrir un espace neutre, bienveillant et d’accueil est parfois nécessaire pour se libérer, mettre du sens et trouver les ressources pour poursuivre son chemin.",
                            ],
                            benefits: [
                                'Faites-vous le constat de vivre régulièrement les mêmes situations bien que vous vous soyez juré « de faire différemment la prochaine fois » ?',
                                "D’avoir ce sentiment de perdre confiance en vous, de ne pas être en phase avec vos ressentis et émotions, ou encore d’avoir l’impression de vivre des échecs ?"
                            ],
                            priceTitle: "Modalité de l'accompagnement : ",
                            prices: [
                                "(RDV possibles en visio)",
                                "1ère séance : 1h30",
                                "Séances suivantes : 1h",
                                "Tarifs : 80€",
                                "L’argent ne doit pas être un frein",
                                "Des solutions de facilité de paiement sont possibles", 
                            ],
                            img: {
                                url: "accompagnements",
                                name: "accompagnement"
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    section:not(.contact){
        text-align: start;
        padding: 1rem;
    }
    li:not(.benefits) {
        list-style: none;
    }

    h2 {
        text-align: center;
    }

    blockquote {
        font-style: italic;
    }

    .wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .wrapper__image {
        text-align: center;
    }

    img {
        width: 50%;
        border-radius: 1rem;
    }

    @media screen and (min-width: 1024px) {
        .wrapper {
            flex-direction: row;
            padding: 2rem;
            align-items: center;
        }

        .wrapper__image {
            width: 50%;
        }

        .wrapper__content {
            width: 50%;
        }
    }
</style>
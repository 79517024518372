<template>
  <div>
    <Nav />
    <h1>BLOG</h1>
    <button v-if="isConnected" @click="createArticle()">Ajouter un article</button>
    <div class="articles__container">
      <div v-for="article in articles" :key="article['_id']" class="article">
        <font-awesome-icon :icon="['fa', 'pen']" class="modify__article" @click="modifyArticle(article['_id'])" />
        <font-awesome-icon :icon="['fa', 'trash']" class="delete__article" @click="deleteArticle(article['_id'])" />
        <h2>{{article.name}}</h2>
        <p>{{article.description}}</p>
        <img src="article.imageUrl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import router from '../../router';
  import Nav from '../navigation/Nav.vue';

  export default {
    name: 'AllArticles',
    components: {
      Nav
    },
    data() {
      return {
        isConnected: localStorage.getItem("isConnected") || false,
        articles: [],
        isModifying: false,
      }
    },
    methods: {
      getArticles: function () {
        var url = "http://localhost:3000/api/articles/";
        axios.get(url)
          .then(response => {
            this.articles = response.data;
            console.log(this.articles);
          })
      },
      deleteArticle: function (id) {
        var url = "http://localhost:3000/api/articles/" + id;
        axios.delete(url)
          .then(response => {
            if (response.status == 200) {
              alert("article supprimé");
              router.push(this.$router.go());
            } else {
              alert("une erreur est survenue " + response.statusText);
            }
          })
      },
      modifyArticle: function (id) {
        router.push("/modifArticle/" + id);
      },
      createArticle: function() {
        router.push("/newArticle");
      }
    },
    mounted() {
      this.getArticles();
    },
  }
</script>

<style>
  .articles__container {
    display: flex;
  }

  .article {
    width: 45%;
    border: 2px solid black;
    border-radius: 2rem;
    padding: 2rem;
    margin: 1rem 2rem;
  }

  .delete__article {
    cursor: pointer;
  }
</style>
<template>
    <div>
        <div>
            <img src="../../assets/logoBE.png" alt="Logo Bulle Essence" />
            <a class="fb" href="https://facebook.com">
              <font-awesome-icon :icon="['fab', 'facebook-square']" /></a>
            <a class="insta" href="https://instagram.com">
              <font-awesome-icon :icon="['fab', 'instagram']" /></a>
          </div>
          <div>
            <h1>« Thérapie du bien-être : Prendre soin du corps, de l’Esprit et de l’Âme »</h1>
            <h2>"Une plongée au coeur de soi, pour panser, réparer et avancer sur votre chemin"</h2>
            <h2>Accompagner les transitions de la naissance à la mort</h2>
            <h2>Doula de fin de vie & Accompagnante des grandes transitions de vie</h2>
          </div>
      
          <button class="appointment-btn" v-if="appointment" @click="closeAppointment()">Fermer la prise de rendez-vous</button>
          <button class="appointment-btn" v-else @click="addAppointment()">Prendre un rendez-vous</button>
          <!-- use the modal component, pass in the prop -->
          <div v-show="appointment" class="calendly-inline-widget" data-url="https://calendly.com/mel-ame-therapy" style="min-width:320px;height:630px;"></div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data() {
          return {
            datePick: "",
            appointment: false,
          };
        },
        methods: {
          addAppointment: function () {
            this.appointment = true;
          },
          closeAppointment: function () {
            this.appointment = false;
          },
        },
        mounted() {
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute(
            "src",
            "https://assets.calendly.com/assets/external/widget.js"
          );
          document.head.appendChild(recaptchaScript);
        },
    }
    
</script>

<style scoped>
    img {
        width: 15%;
        border-radius: 1rem;
      }
    
      a {
        color: black;
      }
    
      .fb:hover {
        color: blue;
      }

      
      .insta:hover {
        color: rgb(226, 43, 195);
      }
      
      svg {
        font-size: 2rem;
        margin: 1rem;
        cursor: pointer;
      }
      .name {
        font-size: 2rem;
        font-weight: 700;
      }
    
      .appointment-btn {
        margin: 1rem;
        padding: 1rem;
        border-radius: .5rem;
        background-color: #d0f0fb;
        width: 50%;
        cursor: pointer;
        font-weight: 800;
      }
</style>
<template>
  <div>
    <Nav />
    <Header />
    <About />
  </div>
</template>

<script>
  import Nav from "./navigation/Nav.vue";
  import About from './presentation/About.vue';
  import Header from "./navigation/Header.vue";

  export default {

    components: {
      Nav,
      About,
      Header
    },
  };
</script>

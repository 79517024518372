<template>
    <div>
        <form @submit="checkForm">
            <input type="email" v-model="email">
            <input type="password" v-model="password">
            <button type="submit">Envoyer</button>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import router from "../../router";

    export default {
        data() {
            return {
                email: null,
                password: null,
                isConnected: false
            }
        },
        methods: {
            checkForm: function () {
                if (this.email && this.password) {
                    axios
                        .post("http://localhost:3000/api/auth/login", {
                            email: this.email,
                            password: this.password
                        }).then(response => {
                            if (response.status == 200) {
                                this.isConnected = true;
                                localStorage.setItem("token", response.data.token);
                                localStorage.setItem("isConnected", this.isConnected);
                                router.push("/allArticles");
                            }
                        }).catch(error => {
                            console.log("NOPE")
                            console.log(error)
                        })
                } else {
                    return alert("NOPE")
                }
            }
        }
    }
</script>
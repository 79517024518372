<template>
    <div>
        <Nav />
        <Header />
        <h1>Les ateliers</h1>
        <div class="card__wrapper">
            <div v-for="atelier in ateliers" :key="atelier.title" :id="atelier.id">
                <h2>{{atelier.title}}</h2>
                <p><i>{{atelier.quote}}</i></p>
                <p>{{atelier.description}}</p>
                <p>{{atelier.group}}</p>
                <p>{{atelier.time}} - {{atelier.price}}</p>
                <p>{{atelier.when}}</p>
                <p>{{atelier.where}}</p>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import Nav from '../navigation/Nav.vue';
    import Header from '../navigation/Header.vue';
    import Footer from '../navigation/Footer.vue';

    export default {
        name: 'Blog',
        components: {
            Footer,
            Nav,
            Header
        },
        data() {
            return {
                ateliers: {
                    bulles: {
                        id:"bulles",
                        title: "Ateliers Répit par les sens",
                        quote: "thème en fonction des demandes",
                        description: "",
                        group: "Groupe de 3 personnes : Personnes en situation de handicap (tous types de handicap, contre indication à l’épilepsie) ou maladies neurodégénératives",
                        time: "1h30",
                        price: "participation libre à partir de 12€",
                        when:"",
                        where: "Lieu : 73 Av. de la Patinière, 38500 Voiron"
                    },
                    mandalas: {
                        id:"mandalas",
                        title: "Mandalas connectés",
                        quote: "Aller à la rencontre de ce qui sommeil en moi, ce qui est dans l’instant, retour au corps par la présence méditative, expression de mes émotions et de mon vécu par la création de mon mandala. Partage, exploration, libération",
                        description: "",
                        group: "6 personnes maximum",
                        time: "3 heures",
                        price: "45€",
                        when:"Une fois par trimestre",
                        where: "Lieu : 73 Av. de la Patinière, 38500 Voiron"
                    },
                    cercle: {
                        id:"femmes",
                        title: "Cercles de femmes",
                        quote: "Explorer son chemin de femme et retrouver sa puissance de Femme",
                        description: "Les cercles de femmes sont une invitation à traverser, éclairer et mettre en conscience des espaces en nous qui sommeillent ou se sont cristallisés au fil de nos expériences. Ces espaces nous offrent  d’infinies possibilités de s’explorer en toute sororité, sécurité, amour, bienveillance, et dans le respect du sacré.",
                        group: "6 femmes",
                        time: "3 heures",
                        price: "30€",
                        when:"Une fois par mois",
                        where: "Lieu : 73 Av. de la Patinière, 38500 Voiron"
                    },
                    deuil: {
                        id:"deuil",
                        title: "Cercles de parole sur le deuil (Un groupe de soutien à visée non thérapeutique)",
                        quote: "Trouver des alliés sur votre chemin de deuil afin de rompre la sensation de solitude qu’engendre la perte d’un proche",
                        description: `Les cercles de parole peuvent vous apporter comme un souffle de liberté dans ce long procès.
                        Se réunir avec des personnes partageant la même traversée, s’apporter de l’écoute bienveillante et de tisser des liens humain.
                        C’est également un espace pour créer et tisser une nouvelle relation avec votre proche défunt au travers de sa mémoire que vous faite vivre à chaque rencontes.
                        Un temps que vous vous offrez pour déposer, évoquer votre proche en toute liberté et sécurité. 
                        Démystifier le sujet de la mort et traverser sa douleur avec le soutien du groupe.
                        `,
                        group: "5 personnes minimum pour ouvrir le groupe : Toutes personnes majeures en deuil récent ou ancien / Toutes personnes accompagnant un proche en fin de vie / Parent en deuil périnatal / Fratries mineurs / Fratrie majeurs",
                        when: "1 fois par mois",
                        time: "1h30",
                        price: "10€",
                        where: "Lieu : 73 Av. de la Patinière, 38500 Voiron"
                        }
                }
            }
        }
    }
</script>

<style scoped>
    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .card__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .card__wrapper div {
        width: 40%;
        padding: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        margin: 2rem;
    }

    @media screen and (max-width: 1440px) {
        .card__wrapper div {
            width: 100%;
        }
    }
</style>
<template>
  <Nav />
  <div>
    <div v-if="loader" class="loader__container">
      <div class="fulfilling-bouncing-circle-spinner">
        <div class="circle"></div>
        <div class="orbit"></div>
      </div>
    </div>
    <router-link to="/allArticles" class="back">Retour</router-link>
    <h2 class="new-post__title">Creation d'un nouvel article</h2>
    <div class="message__container">
      <div>
        <h3>Titre</h3>
        <input type="text" v-model="name">
        <h3>Contenu</h3>
        <textarea class="content-input" v-model="description" />
        <h3>Image</h3>
        <input ref="myImage" class="img-input" type="file" name="imageUrl"/>
        </div>
        <button class="publish" @click="publish()">PUBLIER</button>
      </div>
    </div>
  </template>
  
  <script>

    import axios from 'axios';
import Nav from '../navigation/Nav.vue';
  
    export default {
      components: {
        Nav,
      },
      data() {
        return {
            description: "",
            loader: false,
            name: ""
        };
      },
      methods: {
        publish: function () {
          this.loader = true;
          var url = 'http://localhost:3000/api/articles/';
          
          var formData =  {};
        
          var myHeaders = new Headers();
          //myHeaders.append("Authorization",
            //"Bearer " + localStorage.getItem("token")
          //);
          myHeaders.append( 'Content-Type', 'application/json');
          
          //formData.append("userId", +localStorage.getItem("id"));
          formData.name = this.name;
          formData.description = this.description;
           var addFile = this.$refs.myImage.files.length > 0;
           console.log(addFile)
          if(addFile) {
            formData.imageUrl = this.$refs.myImage.files[0];
          }
          
  
          axios.post(url, (formData))
            .then(response => console.log(response.data))
            .then(() => {
              this.loader = false;
              this.$router.push("/allArticles");
            })
            .catch(error => {
              this.loader = false;
              console.log(error)
            });
        }, 
      }, 
    }
  </script>
  
  <style>
    .back {
      background: #80808040;
      padding: 1rem 4rem;
      font-weight: 700;
      border: 1px solid;
      text-decoration: none;
      color: black;
      cursor: pointer;
    }
  
    .new-post__title {
      margin: 3rem 0;
    }
  
    .message__container {
      border: 1px solid;
      border-radius: 1rem;
      width: 30%;
      margin: auto;
    }
  
    .content-input {
      width: 90%;
      max-width: 90%;
      height: 6rem;
    }
  
    .img-input {
      margin-bottom: 2rem;
    }
  
    .publish {
      padding: 1rem;
      cursor: pointer;
      font-weight: 500;
      border-radius: .5rem;
      margin-bottom: 1rem;
      width: 65%;
    }
  
    .publish:hover {
      color: white;
      background-color: green;
      border: 2px solid green;
    }
  </style>
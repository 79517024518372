<template>
    <div>
        <Nav />
        <Header />
        <h1>MANDALA</h1>
    </div>
</template>

<script>
    import Nav from '../navigation/Nav.vue';
    import Header from '../navigation/Header.vue';

    export default {
        name: 'Mandala',
        components: {
            Nav,
            Header
        },
    }
</script>
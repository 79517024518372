<template>
  <div>
    <section class="about">
      <h2 class="about__title">Qui est Mélanie ?</h2>
      <div class="about-container">
        <div class="about-part1">
          <div class="img-container">
            <img
              class="me"
              src="../../assets/prez-mel.jpeg"
              alt="Photo de Mélanie"
            />
          </div>
          <div class="text-container">
            <p>
              Passionnée par l’être humain et de toutes ses richesses qui
              sommeillent en lui, Mélanie a toujours été très sensible à la
              souffrance des autres. Par sa grande empathie, sa douceur, son
              écoute et sa grande intuition, elle a choisi tout naturellement la
              voie de l’accompagnement.
            </p>
            <p>
              Suivant les élans de son cœur, c’est au travers de ses
              expériences, rencontres, voyages, formations et thérapies qu’elle
              a emprunté le chemin de l’accompagnement des transitions.
            </p>
            <p>
              Comme un appel, Mélanie a su très jeune qu’elle voulait se tenir
              tel un phare auprès des personnes traversant le voile de la mort.
            </p>
            <p>
              D’expériences en expériences, Mélanie fait le constat que la mort
              est un sujet sociétal tabou, et bien souvent un passage froid à
              craindre, sur lequel l’on ne doit pas s’expanser.
            </p>
          </div>
        </div>
        <div class="text-container about-part2">
          <p>
            Lever le voile sur les sujets tabou et mettre de la vie là où il n’y en
            a plus, voilà ce pour quoi Mélanie s’engage.<br />
            Rendre belle chaque transition de vie de la naissance à la mort.
            Insuffler toute l’énergie de vie nécessaire pour rendre doux ce qui
            peut être vécu comme bouleversant et difficile. Peu importe le
            passage dans lequel vous vous trouvez, Mélanie est là avec toute sa
            sagesse et son calme.
          </p>
          <p>
            Co-fondatrice de l’Association Cœur d’étoiles : Accompagner le
            parcours des familles et des bébés en parcours de réanimation néonatale.

            Présidente de l'Annuaire Doula : annuaire permettant de référencer les doulas du monde entier, faire parler du métier de doula de naissance et de fin de vie, tisser un réseau de partenaires engagés. 
          </p>
        </div>
      </div>
    </section>
    <section class="infos">
      <img
        class="roots"
        src="../../assets/roots.jpg"
        alt="arbre avec des racines"
      />
      <div>
        <h2>Les chemins que nous pouvons emprunter pour cheminer</h2>
        <hr />
        <ul>
          <li>La Relation d’Aide : Thérapie par la parole visant à explorer vos mécanismes mentaux, vos territoires intérieurs, votre sphére émotionnelle. Une approche dynamique par ses outils concrets.  </li>
          <li>L'étiomedecine : Technique thérapeutique d'accompagnement émotionnel, allant chercher la racine de votre problématique pour le traiter sur un plan énergétique. Faire du lien entre la santé physique et l'harmonie émotionnelle.   </li>
          

          <li>La Psycho-Généalogie : Thérapie familiale permettant de mettre en lumière vos comportements en miroir de ceux hérités de votre famille. Mettre de la conscience sur votre bagage familiale et briser les liens toxiques. Comprendre des schémas répétitifs et douloureux, reprendre votre place dans votre système. </li>
          <li>Les constellations familiales : Thérapie familiale permettant de libérer les blocages trans-générationnels. Individuelle ou en groupe, tous les sujets sont les bienvenus. : </li>
          <li>Les massages intuitifs et Abhyanga (ayurvéda)</li>
          <li>L’Access Bar : Technique de toucher délicat sur des points situés sur la tête. Offre un relâchement complet du corps et des pensées, lever des croyances limitantes, apaiser les émotions, redonner de la liberté dans sa créativité. </li>
          <li>
            Les soins énergétiques (massage métamorphique, bols tibétains, rêves
            éveillés au tambour) : apaiser les émotions, apaiser le mental, agit sur la confiance en soi et l'estime de soi, régule le corps et les fonctions biologiques.
          </li>  
          <li>Le soin Rebozzo : Thérapie du corps, c'est un soin Rituel de passage dans les moments importants de votre vie. Se réconcilier avec son corps, regrouper ses énergies, réparer en cas de passages douloureux.
          </li>
        </ul>
      </div>
    </section>
    <section class="activity-section">
      <div class="activity">
        <h2>Thérapies</h2>
        <hr />
        <h3>
          Prenez du temps pour vous, afin de vous (re)connecter à vous m'Aime.
        </h3>
        <div class="activity-container">
          <div>
            <img
              src="../../assets/massage.jpeg"
              alt="personne se faisant masser"
            />
            <button
              class="go-to__btn"
              id="show-modal"
              @click="redirectTo('accompagnement#ayurvedique')"
            >
              MASSAGES
            </button>
          </div>
          <div>
            <img
              src="../../assets/psychoge.jpeg"
              alt="paysage nature avec un pont"
            />
            <button class="go-to__btn" @click="redirectTo('accompagnement#psychoge')">
              PSYCHO-GÉNÉALOGIE
            </button>
          </div>
          <div>
            <img
              src="../../assets/accompagnements.jpeg"
              alt="ensemble d'outils pour dessiner des mandalas"
            />
            <button class="go-to__btn" @click="redirectTo('accompagnement#psychocorpo')">
              ACCOMPAGNEMENT PSYCHO CORPOREL
            </button>
          </div>
          <div>
            <img
              src="../../assets/cercle_femmes.jpg"
              alt="femme utilisant un bol tibétain"
            />
            <button class="go-to__btn" @click="redirectTo('ateliers#femmes')">
              CERCLES DE FEMMES
            </button>
          </div>
        </div>
        <div class="activity">
          <h2>Service de Doula de fin de vie (Palliathérapeute)</h2>
          <hr />
          <h3>
            Prenez du temps pour vous, afin de vous (re)connecter à vous m'Aime.
          </h3>
          <div class="activity-container">
            <div>
              <img
                src="../../assets/deuil-perinatal.jpg"
                alt="image de materiel de massage"
              />
              <button
                class="go-to__btn"
                id="show-modal"
                @click="showModalPerinatal = true"
              >
                DEUIL PERINATAL
              </button>
              <Modal
                v-if="showModalPerinatal"
                @close="showModalPerinatal = false"
                :title="perinatal.title"
                :items="perinatal.items"
                @closeExt="closeModal($event, 'showModalPerinatal')"
              ></Modal>
            </div>
            <div>
              <img
                src="../../assets/bien-etre.jpeg"
                alt="paysage nature avec un pont"
              />
              <button class="go-to__btn" @click="showModalIndividuel = true">
                CONSULTATIONS INDIVIDUELLES
              </button>
              <Modal
                v-if="showModalIndividuel"
                @close="showModalIndividuel = false"
                :title="individuel.title"
                :items="individuel.items"
                @closeExt="closeModal($event, 'showModalIndividuel')"
              ></Modal>
            </div>
            <div>
              <img
                src="../../assets/celeb-funéraire.jpeg"
                alt="ensemble d'outils pour dessiner des mandalas"
              />
              <button class="go-to__btn" @click="showModalFuneraire = true">
                CÉLÉBRATION FUNÉRAIIRE LAÏQUE
              </button>
              <Modal
                v-if="showModalFuneraire"
                @close="showModalFuneraire = false"
                :title="funeraire.title"
                :items="funeraire.items"
                @closeExt="closeModal($event, 'showModalFuneraire')"
              ></Modal>
            </div>
            <div>
              <img
                src="../../assets/cercle-parole.jpg"
                alt="femme avec tambour et pendule"
              />
              <button class="go-to__btn" @click="redirectTo('ateliers#deuil')">
                CERCLES DE PAROLE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
  import Modal from "../utils/Modal.vue";
  import Footer from "../navigation/Footer.vue";

  export default {
    components: {
      Modal,
      Footer,
    },
    methods: {
      closeModal(event, type) {
        var value = event.target.classList.value;
        if (value.includes("modal-wrapper")) {
          switch (type) {
            case "showModalMass":
              this.showModalMass = false;
              break;
            case "showModalPsychoG":
              this.showModalPsychoG = false;
              break;
            case "showModalAide":
              this.showModalAide = false;
              break;
            case "showModalAccess":
              this.showModalAccess = false;
              break;
            case "showModalPerinatal":
              this.showModalPerinatal = false;
              break;
            case "showModalIndividuel":
              this.showModalIndividuel = false;
              break;
            case "showModalFuneraire":
              this.showModalFuneraire = false;
              break;
          }
        }
      },
      redirectTo(name) {
        this.$router.push(`/${name}`)
      },
    },
    data() {
      return {
        showModalMass: false,
        showModalPsychoG: false,
        showModalAide: false,
        showModalAccess: false,
        showModalPerinatal: false,
        showModalIndividuel: false,
        showModalFuneraire: false,
        massages: {
          title: "Les massages",
          items: [
            {
              name: "Abhyanga",
              content: "Massage indien",
              price: "60€",
            },
            {
              name: "Intuitif",
              content: "Massage au feeling",
              price: "70€",
            },
          ],
        },
        ateliers: {
          title: "Les ateliers",
          items: [
            {
              name: "Cercles de femme",
              content:
                "Explorer son chemin de femme en sororité, sécurité, amour, bienveillance, et avec sacré",
              price: "3H / 30€",
              when: "1x / mois",
              who: "6 femmes",
            },
            {
              name: "Mandalas connectés",
              content:
                "Aller à la rencontre de votre inconscient, ce qui est dans l’instant, retour au corps par la présence méditative, expression de ses émotions et de son vécu par la création de votre mandala. <br />Partage, exploration, libération",
              price: "3H / 45€",
              when: "1x / trimestre",
              who: "6 personnes maximum",
            },
            {
              name: "Bulles sensorielles",
              content:
                "Un voyage au coeur de Soi : Faire connaissance avec mon corps. Ateliers Répit par les sens à thème en fonction des demandes",
              price: "1H30 / participation libre et consciente à partir de 12e",
              when: "1x / trimestre",
              who: "Groupe de 3 personnes ( Personnes avec Autisme, alzheimer)",
            },
          ],
        },
        relations: {
          title: "La relation d'aide",
          items: [
            {
              name: "Format classique",
              content: "Tu dors mais pas vraiment",
              price: "1H / 60€",
              when: "1er RDV 60e / 1 H 30",
            },
            {
              name: "Format long ponctuel",
              content: "Transe de ouf",
              price: "1H30 / 75€",
            },
            {
              name: "Forfait 5 séances à répartir au besoin, à votre rythme",
              content: "Tu redeviens un enfant",
              price: "7H30 / 300€ (Soit 1H30 / 60€)",
            },
          ],
        },
        perinatal: {
          title: "Doula dans la traversée du deuil périnatal",
          items: [
            {
              name:
                "« Ce sont les étoiles, les étoiles tout là-haut qui gouvernent notre existence » William Shakespeare",
              content:
                "Parfois notre amour est intense mais ne peut s’exprimer que de cours instants, ces petits Êtres croisent notre route pour un court passage. Dans le deuil périnatal, chaque histoire est unique et a sa place, chaque histoire est importante. <br />Mélanie vous accompagne :",
              list: [
                "Sur le chemin du deuil de la perte de votre petit Être",
                "Dans le processus de l’interruption naturel de votre grossesse",
                "Dans le processus de l’interruption médical de votre grossesse",
                "Dans le processus de l’interruption volontaire de votre grossesse",
              ],
            },
          ],
        },
        individuel: {
          title: "Consultations individuelles dans le cadre de deuils",
          items: [
            {
              name:
                "La perte d’un être cher relève bien souvent d’une traversée aux milles paysages.",
              content: " C’est sur ce chemin de survivance que Mélanie peut vous proposer : ",
              list: [
                "Avant, pendant et après la perte de l’être cher",
                "D’être le capitaine de votre bateau pour naviguer en eaux troubles",
                "Trouver un sens à la perte",
                "Trouver une légèreté et une liberté face à cette disparition",
              ],
            },
          ],
        },
        funeraire: {
          title: "Célébration funéraire laïque",
          items: [
            {
              name:
                "Mélanie par sa connexion aux traditions ancestrales, honore et rend sacré les passages de la vie par des rituels ",
              content:
                "C’est rendre sacré et honorer les étapes franchies durant la vie durant. Elle vous propose avec sa plume et son cœur",
              list: [
                "De célébrer et rendre hommage durant la célébration funéraire ",
                "De préparer avec vous les funérailles de votre proche",
                "De créer des rituels sur mesures pour vos passages (deuil périnatal, perte d’un parent,  perte d’un enfant, perte d’un proche, d’un animal, les étapes franchies dans votre processus de deuil…)",
                "En Maison funéraire, à domicile ou en nature",
              ],
            },
          ],
        },
      };
    },
  };
</script>

<style scoped>
  section {
    border-radius: 0.5rem;
  }

  .about {
    background-image: url(../../assets/india-bckgd.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: 2rem 1rem 1rem 1rem;
  }

  .about__title {
    margin-top: 0;
    font-size: 2rem;
    font-weight: 700;
    color: white;
  }

  .text-container {
    width: 50%;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    margin-right: 3rem;
  }

  .about-part1 {
    flex-direction: column-reverse;
    display: flex;
  }

  .about-part2 {
    width: 100%;
    margin-right: 0;
  }

  .about-part1 div {
    width: 100%;
  }

  .img-container {
    width: 50%;
  }

  .me {
    width: 100%;
    margin: 2rem 0;
    border-radius: 1rem;
  }

  .infos {
    margin-top: 0.2rem;
    display: flex;
  }

  .roots {
    display: none;
    width: 30%;
    border-radius: 0.4rem;
    margin: auto;
    padding: 1rem;
    border-radius: 2rem;
  }

  .infos li {
    text-align: initial;
  }

  .infos li:not(:last-child) {
    margin-bottom: 1rem;
  }

  .activity-section {
    background-image: linear-gradient(
        rgba(240, 159, 84, 0.5),
        rgba(110, 110, 56, 0.5)
      ),
      url(../../assets/valley.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    color: white;
    font-weight: 700;
    padding: 1rem;
    opacity: 0.8;
  }

  .activity {
    width: 90%;
    margin: auto;
  }

  .activity-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
  }

  .activity-container div {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .activity-container img {
    width: 100%;
    border-radius: 2.3rem;
    height: 12rem;
  }

  .go-to__btn {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
    background-color: #f0ad4e;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 768px) {
    .activity-container div {
      width: 30%;
    }

    .roots {
      display: block;
    }

    .me {
      width: 60%;
    }
  }

  @media (min-width: 1024px) {
    .img-container {
      align-self: center;
    }
    .about-part1 {
      flex-direction: row;
    }

    .about-part2 {
      text-align: left;
      padding-left: 3rem;
      max-width: 90%;                                                               
    }

    .me {
      width: 90%;
    }
  }
</style>

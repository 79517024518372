<template>
    <section class="contact">
        <h2>Contact</h2>
        <div class="contact-container">
            <div>
                <h3>
                    <font-awesome-icon :icon="['fa', 'envelope']" class="contact--icons" />
                </h3>
                    <Email />
            </div>
            <div class="contact--list">
                <h3>
                    <font-awesome-icon :icon="['fa', 'phone']" class="contact--icons" />
                </h3>
                    <p><a href="tel:06-69-48-11-25">06.69.48.11.25</a></p>
                    <p><a href="mailto:cabinetmare.psychocorporel@gmail.com">cabinetmare.psychocorporel@gmail.com</a></p>
                    <p>Adresse : 73 Av. de la Patinière, 38500 Voiron</p>
                    <p>
                        Cabinet nomade : Je peux vous accueillir sur Châbon, également en institution, à domicile et en nature. 
                        Je suis en déplacement une fois par mois sur Marseille. 
                        Visioconférence possible. 
                    </p>
                    

            </div>
            <div>
                <h3>
                    <font-awesome-icon :icon="['fa', 'user']" class="contact--icons"/>
                </h3>
                <ul class="partners__list">
                    <li><a class="partners__link" href="https://julie-attard.wixsite.com/julie-doula-grenoble"
                            target="_blank" rel="noopener noreferrer">Julie Attard - Doula</a></li>
                    <li><a class="partners__link" href="https://www.hypnose-voiron-grenoble.com/"
                            target="_blank" rel="noopener noreferrer">Guillaume Pinard - Hypnothérapeute</a></li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import Email from '../utils/Mail.vue';
    export default {
        name: "Footer",
        components: {
            Email
        },
    }
</script>

<style scoped>
    .contact {
        background-color: #bbb4a580;
        margin-top: 2rem;
        padding: 1rem;

    }

    .contact-container {
        display: flex;
    }

    .contact-container div {
        width: 50%;
        padding: 1rem;
    }
    .contact--list {
        text-align: left;
    }

    .contact--list h3 {
        text-align: center;
    } 

    .contact--icons {
        font-size: 3rem;
        color: #9C7EAD;
    }

    .phone {
        margin-top: 3rem;
    }

    .phone a {
        text-decoration: none;
        padding: 1rem;
        border: 2px solid;
    }


    .partners__list li{
        list-style: none;
        text-align: justify;
    }

    ul {
        padding: 0;
        text-align: left;
    }

    ul li {
        margin: 1rem 0;
    }

    .partners__link {
        text-decoration: none;
        color: black;
        font-weight: bold;
    }

    @media screen and (max-width: 1024px) {
        .contact-container {
            flex-direction: column;
        }
        .contact-container div {
            width: 100%;
        }
    }
</style>
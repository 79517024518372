<template>
    <div>
        <Nav />
        <Header />
        <h2>Doula de fin de vie</h2>
        <p><i>Parler de la mort c’est parler de la vie…C’est aussi se préparer au grand voyage</i></p>
        <div>
            <h3>Qu’est-ce qu’une Doula de fin de vie ou Thanadoula ? </h3>
            <div class="content">
                <div class="content__text">
                    <p>Un nouveau métier en pleine expansion dans la continuité d’un concept existant depuis des
                        milliers d’années dans de nombreuses cultures. Le mot doula est tiré de la Grèce antique,
                        signifiant « servante à la disposition d’autrui ».</p>
                    <p>La mort est un processus dont nous ignorons tout, qui se montre exigeant et difficile … </p>
                    <p>Il se présente à nous sur notre parcours par bien des manières. Souvent, c’est dans un quotidien
                        rempli d’émotions, d’imprévus et essentiellement d’inconnus que nous nous retrouvons.</p>
                    <p>Aider à ce que la mort soit de toute beauté, sacré, empreinte de sérénité, de douceur, de
                        sécurité afin
                        de toucher à une forme de paix intérieure.</p>

                    <p>Soutenir la famille et la personne en fin de vie dans ses choix, de mourir chez eux, ou en
                        institution.
                    </p>
                </div>
                <figure class="quote__container">
                    <blockquote>
                        <p>La présence au monde d’un disparu reste solidement accrochée à nos vies grâce aux mots
                            […]Puisse son âme tissée au fil de nos vies</p>
                    </blockquote>
                    <figcaption>— Marie de Hennezel, <cite>Vivre avec l’invisible</cite></figcaption>
                </figure>
            </div>
            <hr class="separator">
            <div class="description__container">
                <div class="description__container--img__container" data-aos="fade-right" data-aos-easing="linear"
                    data-aos-duration="500">
                    <img class="description__container--img" src="../../assets/photo-pa-fleurs.jpeg"
                        alt="personne agée avec des fleurs dans les mains">
                </div>
                <div class="description__container--text__container" data-aos="fade-left" data-aos-easing="linear"
                    data-aos-duration="1000">
                    <p>Le processus de la mort se déroule sur une dimension autant sociale qu’affective, et ritualisée.
                        Ces
                        trois dimensions dans l’expérience de la mort ne sont généralement pas considérées à la grandeur
                        de ce
                        qu’éprouve la personne en fin de vie et ses proches.</p>

                    <p>La doula de fin de vie à l’époque étaient ces femmes expérimentées qui soutenaient, aidaient les
                        femmes
                        enceintes à traverser le processus de la maternité, de l’accouchement et du postpartum.</p>

                    <p>Il y avait aussi ces autres femmes qui étaient présentes au chevet des personnes qui allaient
                        mourir et
                        de leur famille.</p>

                    <p>Les doulas de fin de vie existent de part le monde et ce depuis une dizaine
                        d’années.</p>
                </div>
            </div>
            <hr class="separator">
            <h3><i>La doula de fin de vie propose un accompagnement holistique à la personne où la maladie engage le
                    pronostic vital :</i></h3>
            <div class="doula__information__container">
                <ul class="doula__information__list">
                    <li data-aos="fade-right" data-aos-delay="100"><span>&rarr;</span> Un soutien pratique pour la personne
                        concernée et les
                        proches (offrir du temps de répit)</li>
                    <li data-aos="fade-right" data-aos-delay="200"><span>&rarr;</span> Un soutien émotionnel</li>
                    <li data-aos="fade-right" data-aos-delay="300"><span>&rarr;</span> Un soutien énergétique</li>
                    <li data-aos="fade-right" data-aos-delay="400"><span>&rarr;</span> Un espace d’ouverture vers la
                        spiritualité</li>
                    <li data-aos="fade-right" data-aos-delay="500"><span>&rarr;</span> Une présence significative,
                        rassurante, empathique,
                        respectueuse, bienveillante et dans la dignité
                    </li>
                    <li data-aos="fade-right" data-aos-delay="600"><span>&rarr;</span> Rester au chevet</li>
                    <li data-aos="fade-right" data-aos-delay="700"><span>&rarr;</span> Organiser des veillées du corps</li>
                    <li data-aos="fade-right" data-aos-delay="800"><span>&rarr;</span> Aide à la préparation des rites
                        funéraires</li>
                    <li data-aos="fade-right" data-aos-delay="900"><span>&rarr;</span> Offrir guidance et écoutes aux
                        endeuillés</li>
                    <li data-aos="fade-right" data-aos-delay="1000"><span>&rarr;</span> Pas d’acte médical</li>
                    <li data-aos="fade-right" data-aos-delay="1100"><span>&rarr;</span> Soutien administratif</li>
                </ul>
                <img class="doula__information__img" src="../../assets/cascade-tambour.jpeg" alt="woman with a drum in front of a cascade" data-aos="zoom-in"
                data-aos-duration="3000">
            </div>
            <hr class="separator">
        </div>

        <div>
            <h3>A qui s’adressent mes services ?</h3>
            <ul class="service__container">
                <li><font-awesome-icon class="service__icons" :icon="['fa', 'star-of-life']" />Personnes avec un pronostic vital engagé, fin de vie</li>
                <li><font-awesome-icon class="service__icons" :icon="['fa', 'seedling']" />Proches d’une personne en fin de vie</li>
                <li><font-awesome-icon class="service__icons" :icon="['fa', 'hand-holding-heart']" />Personnes en deuil (périnatal, pédiatrique, maladie longue, décès traumatique</li>
                <li><font-awesome-icon class="service__icons" :icon="['fa', 'house-user']" />A domicile, en institution, en cabinet sur Voiron ou à distance</li>
                <li><font-awesome-icon class="service__icons" :icon="['fa', 'heart']" />Personnes se questionnant sur leur fin de vie proche ou lointaine, à la mort ou au deuil</li>
            </ul>
        </div>
    </div>
    <Footer />
</template>

<script>
    import Nav from '../navigation/Nav.vue';
    import Header from '../navigation/Header.vue';
    import Footer from '../navigation/Footer.vue';

    export default {
        name: 'Doula',
        components: {
            Nav,
            Header,
            Footer
        },
    }
</script>



<style scoped>
    .quote__container {
        width: 20%;
        margin: auto;
    }

    blockquote {
        margin: 0;
    }

    blockquote p {
        padding: 15px;
        background: #eee;
        border-radius: 5px;
    }

    blockquote p::before {
        content: '\201C';
    }

    blockquote p::after {
        content: '\201D';
    }

    .content {
        display: flex;
    }

    .content__text {
        width: 75%;
        padding: 2rem;
    }

    .description__container {
        display: flex;
        align-items: center;
    }

    .content__text p {
        text-align: justify;
    }

    .description__container--text__container {
        width: 60%;
        padding: 2rem;
    }

    .description__container--img__container {
        width: 40%;
        margin-left: 3%;
    }

    .description__container--text__container p {
        text-align: right;
    }

    .description__container--img {
        width: 50%;
        border-radius: 1rem;
        margin-right: 0;
    }

    .doula__information__container {
        display: flex;
        justify-content: space-around;
    }

    .doula__information__list {
        list-style: none;
        text-align: start;
    }

    .doula__information__list li {
        margin: 1rem 0;
    }

    .doula__information__img {
        width: 25%;
        border-radius: 1rem;
    }

    .separator {
        width: 50%;
        margin: 2rem auto;
    }

    .service__container {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        justify-content: center;
    }

    .service__container li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        padding: 1rem;
    }

    .service__icons {
        font-size: 3rem;
        margin: 2rem 0;
    }

    @media screen and (max-width: 768px) {
        .quote__container {
            width: 30%;
        }

        .description__container,
        .doula__information__container {
            flex-direction: column-reverse;
        }

        .doula__information__img,
        .description__container--img {
            width: 100%;
        }
    }
</style>
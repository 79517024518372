<template>
    <Nav />
    <div>
        <input type="text" placeholder="Titre de l'article" v-model="article.name">
        <textarea name="description" v-model="article.description"></textarea>
        <img :src="article.imageUrl" alt="">
        <input class="input-img" ref="myImage" type="file" @change="updateImg($event)" id="imgInp">
    </div>
    <div>
        <button @click="addModif()">Appliquer</button>
        <button @click="cancel()">Annuler</button>
        <button @click="deletePost()">Supprimer</button>
    </div>
</template>

<script>
    import axios from 'axios';
    import router from '../../router';
    import Nav from '../navigation/Nav.vue';

    export default {
        data() {
            return {
                id: "",
                previewImg: "",
                article: []
            };
        },
        methods: {
            getOneArticle: function () {
                this.id = this.$route.params.id;
                var url = "http://localhost:3000/api/articles/" + this.id;
                axios.get(url)
                    .then(response => {
                        this.article = response.data;
                        console.log(this.article);
                    });
            },
            updateImg: function (evt) {
                var [file] = evt.srcElement.files;
                if (file) {
                    this.article.imageUrl = URL.createObjectURL(file);
                }
            },
            deleteArticle: function () {
                var url = "http://localhost:3000/api/articles/" + this.id;
                axios.delete(url)
                    .then(response => {
                        if (response.status == 200) {
                            alert("article supprimé");
                            router.push("/allArticles");
                        } else {
                            alert("une erreur est survenue " + response.statusText);
                        }
                    });
            }
        },
        mounted() {
            this.getOneArticle();
        },
        components: {
            Nav
        }
    }
</script>
<template>
    <div id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" @click="$emit('closeExt', $event)">
                    <div class="modal-container">
                        <div class="modal-footer">
                            <button class="modal-default-button" @click="$emit('close')">
                                X
                            </button>
                        </div>
                        <div class="modal-header">
                            <h1 class="modal-header-title">{{title}}</h1>
                        </div>

                        <div class="modal-body">
                            <ul>
                                <li v-for="(item, index) in items" :key="index" class="modal-content">
                                    <h4>{{item.name}} : </h4>
                                    <p v-html="item.content"></p>
                                    <p>{{item.who ? "- " : ""}} {{item.who}}</p>
                                    <p>{{item.price ? "- " : ""}} {{item.price}}</p>
                                    <p>{{item.when ? "- " : ""}} {{item.when}}</p>
                                    <p>{{item.where ? "- " : ""}} {{item.where}}</p>
                                    <ul v-if="item.list" class="list-items">
                                        <li v-for="(list, index) in item.list" :key="index">{{list}}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        props: ['title', 'items'],
        methods: {
            isEmpty(value) {
                return value == "" || value == null;
            }
        }
    }
</script>
<style>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 90%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
        color: black;
    }

    .modal-header-title {
        font-weight: 900;
    }

    h4 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
        font-weight: normal;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .modal-body ul:not(.list-items){
        list-style: none;
        text-align: left;
        display: flex;
        justify-content: space-around;
    }

    .modal-content {
        padding: 1rem;
        border: 3px solid rebeccapurple;
        border-radius: 1rem;
        margin: 0 1rem;
    }

    .list-items {
        display: block;
        list-style: circle;
    }

</style>
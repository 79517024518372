import { createRouter, createWebHashHistory } from "vue-router";

import Main from "../components/Main.vue";
import Accompagnement from "../components/individuel/Accompagnement.vue";
import Doula from "../components/individuel/Doula.vue";
import Ateliers from "../components/groupe/Ateliers.vue";
import Mandala from "../components/groupe/Mandala.vue";
import Stage from "../components/groupe/Stage.vue";
import AllArticles from "../components/blog/AllArticles.vue";
import NewArticle from "../components/blog/NewArticle.vue";
import ModifArticle from "../components/blog/ModifArticle.vue";
import Admin from "../components/admin/AdminConnection.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/accompagnement",
    name: "Accompagnement",
    component: Accompagnement,
  },
  {
    path: "/doula",
    name: "Doula",
    component: Doula,
  },
  {
    path: "/ateliers",
    name: "Ateliers",
    component: Ateliers,
  },
  {
    path: "/mandala",
    name: "Mandala",
    component: Mandala,
  },
  {
    path: "/stage",
    name: "Stage",
    component: Stage,
  },
  {
    path: "/allArticles",
    name: "AllArticles",
    component: AllArticles,
  },
  {
    path: "/newArticle",
    name: "NewArticle",
    component: NewArticle,
  },
  {
    path: "/modifArticle/:id",
    name: "ModifArticle",
    component: ModifArticle,
  },
  {
    path: "/adminConnection",
    name: "Admin",
    component: Admin,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

export default router;
